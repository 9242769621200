<template>
  <layout
    :activeIndex="activeIndex"
    :activeModel.sync="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <!--    <template #top>-->
    <!--    </template>-->
    <template #left-0>
      <left-menu
        :floors="floors"
        :system="system"
        @select="leftMenuSelect"
      ></left-menu>
    </template>

    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>
    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          responsive
          striped
        >
          <template v-slot:cell(params)="data">
            <template v-for="(param, param_index) in data.item.params">
              <span
                v-if="param.param_name"
                :style="param.cur_value === null ? 'opacity:0.3' : ''"
              >
                <span class="mx-50" v-if="param_index !== 0">,</span>
                <span>{{
                  param.param_name.replace(data.item.equip_name + "-", "")
                }}</span
                >:
                <span v-if="param.cur_value === null">N/A</span>
                <span v-else>{{
                  param.unit && param.unit.indexOf("||") > -1
                    ? param.unit.split("||")[param.cur_value > 0 ? 1 : 0]
                    : param.cur_value + " " + (param.unit || "")
                }}</span>
              </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm"
                >查看
              </b-button>
              <b-button
                variant="success"
                size="sm"
                @click="equip_to_detail_model(data.item)"
                :disabled="!data.item.detail_model_key"
                >详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      />
    </template>
    <template #left-2>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <b-form-group>
          <label>设备名称</label>
          <b-form-input placeholder="输入设备名称查询" trim></b-form-input>
        </b-form-group>
        <b-form-group>
          <label>报警时间</label>
          <b-form-input placeholder="输入设备名称查询" trim></b-form-input>
        </b-form-group>
      </b-card>
    </template>
    <template #right-2>
      <b-card no-body>
        <b-table
          ref="alarm_list_table_ref"
          :busy="isBusy"
          :empty-text="$t('No Data')"
          :sort-by.sync="alarm_table_sortBy"
          :sort-desc.sync="alarm_table_sortDesc"
          bordered
          class="text-center position-relative"
          primary-key="alarm_id"
          responsive
          small
          striped
          :current-page="alarm_table_currentPage"
          :fields="alarm_table_fields"
          :items="get_fire_alarm_list"
          :per-page="alarm_table_perPage"
          hover
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
          <template #cell(index)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">
                {{ data.index + 1 }}
              </span>
            </div>
          </template>
          <template #cell(Flag)="data">
            <strong v-if="data.item.Flag === -1" class="text-success"
              >已生成工单</strong
            >
            <strong v-else-if="data.item.Flag === 0" class="text-secondary"
              >已解除</strong
            >
            <strong v-else class="text-warning">待处理</strong>
          </template>
          <template #cell(actions)="data">
            <div class="align-middle text-center w-100" style="width: 9rem">
              <b-button
                :disabled="!(data.item.Flag === 1)"
                :variant="
                  'outline-' + (data.item.Flag === 1 ? 'success' : 'secondary')
                "
                class="px-50"
                size="sm"
                @click="clear_fire_alarm_actions(data.item)"
              >
                解除报警
              </b-button>
              <b-button
                :disabled="!(data.item.Flag === 1)"
                :variant="
                  'outline-' + (data.item.Flag === 1 ? 'warning' : 'secondary')
                "
                class="px-50 ml-50"
                size="sm"
                @click="submit_fire_alarm_actions(data.item)"
              >
                生成工单
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="mx-2 my-1">
          <b-row>
            <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
              cols="12"
              sm="6"
            >
              <span class="text-muted">
                {{
                  `
                    ${$t("Show")}
                    ${alarm_table_data_meta.from}
                    ${$t("to")}
                    ${alarm_table_data_meta.to}
                    ${$t("of")}
                    ${alarm_table_rows}
                    ${$t("Entries")}
                    `
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
              cols="12"
              sm="6"
            >
              <b-pagination
                v-model="alarm_table_currentPage"
                :per-page="alarm_table_perPage"
                :total-rows="alarm_table_rows"
                hide-goto-end-buttons
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </template>
    <template #right-3>
      <panel-group :show-title="false" group-uid="e0d5a49d"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { computed, reactive, ref, toRefs } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import { clearFireAlarm, getFireAlarmList, submitFireAlarm } from "@/api/jsf35";
import { watch } from "@vue/composition-api/dist/vue-composition-api";
import { showToast } from "@/libs/utils/showToast";
import { routerParams } from "@/libs/utils/routerParams";
import { showConfirm } from "@/libs/utils/showConfirm";
import axios from "axios";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "JsfIntelligentFireFighting",
  components: {
    Layout,
    LeftMenu,
    vSelect,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    const project_id = routerParams("project_id");
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "fire_fighting_system/fire_fighting_system/fire_fighting_system",
            dbIds: ["equipment_"],
            // activeDbIds: ["equipment_YGF10-02"],
          },
        },
        {
          title: "设备列表",
        },
        {
          title: "报警记录",
        },
        /* { title: "数据分析" }, */
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "RF",
          value: 13,
          model: {
            path: "fire_fighting_system/fire_fighting_system_ROOF/fire_fighting_system_ROOF",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F12",
          value: 12,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F11/fire_fighting_system_F11",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F11",
          value: 11,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F10/fire_fighting_system_F10",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F10",
          value: 10,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F9/fire_fighting_system_F9",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F9",
          value: 9,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F8/fire_fighting_system_F8",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F8",
          value: 8,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F7/fire_fighting_system_F7",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F7",
          value: 7,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F6/fire_fighting_system_F6",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F5/fire_fighting_system_F5",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F5",
          value: 5,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F4/fire_fighting_system_F4",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F3",
          value: 3,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F3/fire_fighting_system_F3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F2",
          value: 2,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F2/fire_fighting_system_F2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "F1",
          value: 1,
          model: {
            path: "fire_fighting_system/fire_fighting_system_F1/fire_fighting_system_F1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B1",
          value: -1,
          model: {
            path: "fire_fighting_system/fire_fighting_system_B1/fire_fighting_system_B1",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "fire_fighting_system/fire_fighting_system_B2/fire_fighting_system_B2",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {
            path: "fire_fighting_system/fire_fighting_system_B3/fire_fighting_system_B3",
            dbIds: ["equipment_"],
          },
        },
        {
          title: "B5",
          value: -5,
          model: {
            path: "fire_fighting_system/fire_fighting_system_B4/fire_fighting_system_B4",
            dbIds: ["equipment_"],
          },
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      // activeModel.value = event.model
      layoutParams.activeModel = event.model;
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    //设备表参数
    const equip_table = reactive({
      fields: [
        { label: "ID", key: "equip_id" },
        { label: "设备名称", key: "equip_name" },
        { label: "设备编号", key: "equip_meter_code" },
        { label: "楼层", key: "floor" },
        { label: "参数状态", key: "params" },
        { label: "操作", key: "action" },
      ],
      items: [],
      perPage: 16,
      currentPage: 1,
      rows: 1,
    });

    watch(
      () => store.state.jsf35.equip_list,
      (list) => {
        equip_table.items = list;
        equip_table.rows = list.length;
      }
    );

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };

    const alarm_list_table_ref = ref(null);

    //报警表参数
    const alarm_table = reactive({
      isBusy: false,
      alarm_table_filters: {
        ReadingDt: "",
        MeterCode: "",
        pvchinaname: "",
      },
      alarm_table_fields: [
        { key: "index", label: "#" },
        { key: "MeterCode", label: "报警设备" },
        { key: "pvchinaname", label: "位置" },
        { key: "InsertVal", label: "报警内容" },
        { key: "ReadingDt", label: "报警时间" },
        //处理状态：1-未处理，0-已解除，-1-已提交工单，
        { key: "Flag", label: "处理状态" },
        { key: "actions", label: "操作" },
      ],
      alarm_table_items: [],
      alarm_table_perPage: 20,
      alarm_table_currentPage: 1,
      alarm_table_rows: 1,
      alarm_active_index: null,
      alarm_table_sortBy: "Flag",
      alarm_table_sortDesc: false,
      alarm_table_data_meta: computed(() => {
        const localItemsCount = alarm_list_table_ref.value
          ? alarm_list_table_ref.value.localItems.length
          : 0;
        const perPage = alarm_table.alarm_table_perPage;
        const currentPage = alarm_table.alarm_table_currentPage;
        return {
          from: perPage * (currentPage - 1) + (localItemsCount ? 1 : 0),
          to: perPage * (currentPage - 1) + localItemsCount,
          of: alarm_table.alarm_table_rows,
        };
      }),
    });

    watch(
      [
        () => alarm_table.alarm_table_currentPage,
        () => alarm_table.alarm_table_perPage,
      ],
      () => {
        refetchFireAlarmData();
      }
    );

    watch(
      () => alarm_table.alarm_table_filters,
      () => {
        refetchFireAlarmData();
      },
      "deep"
    );

    const refetchFireAlarmData = () => {
      alarm_list_table_ref.value.refresh();
    };

    //获取火灾报警列表
    const get_fire_alarm_list = (ctx, callback) => {
      alarm_table.isBusy = true;
      let params = {
        project_id: project_id,
        // ...alarm_table.alarm_table_filters,
      };
      params = {
        ...params,
        sortBy: alarm_table.alarm_table_sortBy,
        sortDesc: alarm_table.alarm_table_sortDesc ? 1 : 0,
        pagesize: alarm_table.alarm_table_perPage,
        page: alarm_table.alarm_table_currentPage,
      };
      getFireAlarmList(params)
        .then((response) => {
          const { list, total } = response.data;
          callback(list);
          alarm_table.alarm_table_rows = total;
          alarm_table.isBusy = false;
        })
        .catch((error) => {
          alarm_table.isBusy = false;
          showToast(error);
        });
    };

    //解除报警
    const clear_fire_alarm_actions = (item) => {
      showConfirm("是否确认解除此报警？", () => {
        clearFireAlarm({
          project_id: project_id,
          MeterCode: item.MeterCode,
        })
          .then(() => {
            showToast(null, "成功", "解除成功！");
            refetchFireAlarmData();
          })
          .catch((error) => {
            showToast(error);
          });
      });
    };

    //提交报警工单
    const submit_fire_alarm_actions = (item) => {
      showConfirm("是否确认为此报警生成工单？", () => {
        axios
          .post("https://app.dreamsfount35.com/api/alarm/addAlarmOrder", {
            id: item.StatusID,
            type: "fire",
          })
          .then((response) => {
            if (response.data.status === "success") {
              submitFireAlarm({
                project_id: project_id,
                MeterCode: item.MeterCode,
              })
                .then(() => {
                  showToast(null, "成功", "提交火警报警工单成功！");
                  refetchFireAlarmData();
                })
                .catch((error) => {
                  showToast(error);
                });
            } else {
              showToast(null, "失败", response.data.msg);
            }
          })
          .catch((error) => {
            console.log("err", error);
            showToast(error);
          });
      });
    };
    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "ROOF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      equip_table.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      equip_table.rows = equip_table.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(equip_table),
      ...toRefs(alarm_table),
      alarm_list_table_ref,
      clear_fire_alarm_actions,
      submit_fire_alarm_actions,
      get_fire_alarm_list,
      equip_to_detail_model,
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep tr.table-warning td {
  color: #3b4253;
}

.types {
  position: absolute;
  right: 4rem;
  top: 0;
  display: flex;

  .type {
    height: 4rem;
    margin-left: 3rem;
    display: flex;
    align-items: center;

    .icon {
      width: 2rem;
      height: 2rem;
    }

    span {
      margin-left: 0.5rem;
    }
  }
}
</style>
